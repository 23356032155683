import axios from "axios";

const SmsApiService = {
    ApiBaseUrl: "/exapi/",
    async Call(params, isBlob = false) {
        const header = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        const ax = axios.create({
            baseURL: this.ApiBaseUrl,
            headers: header,
            responseType: isBlob ? "blob" : undefined,
        });

        var r;
        try {
            r = await ax.post("api.php", params);
        } catch (e) {
            r = null;
        }

        return r;
    },

    async get_balance() {
        const req = {
            command: "get_balance",
            params: {},
        };

        return await this.Call(req);
    },

    async get_message(params) {
        const req = {
            command: "get_message",
            params: params,
        };

        var res = await this.Call(req);

        return res.data;
    },

    async get_message_ex(params) {
        const req = {
            command: "get_message_ex",
            params: params,
        };

        var res = await this.Call(req);

        return res.data;
    },

    async send_messages_ex(params) {
        params.subject = " ";
        if (process.env.VUE_APP_ISTEST) {
            params.to = params.to.map((element) => {
                element = "99" + element;
                return element;
            });
        }

        const req = {
            command: "send_messages_ex",
            params: params,
        };

        var res = await this.Call(req);
        return res.data;
    },

    async send_messages(params) {
        params.forEach((e) => {
            e.subject = " ";
            if (process.env.VUE_APP_ISTEST) {
                e.to = "99" + e.to;
            }
        });
        // if (process.env.VUE_APP_ISTEST) {
        //     params.forEach((element) => {
        //         element.to = "99" + element.to;
        //     });
        // }

        const req = {
            command: "send_messages",
            params: params,
        };

        // Testserver 일경우,

        var res = await this.Call(req);

        return res.data;
    },

    async get_message_excel(params) {
        const isValidDate = (date) => {
            if (!date) return false;
            date = new Date(date);
            return date.toString() !== "Invalid Date" && !isNaN(date);
        };

        try {
            const { start, end } = params;
            if (!isValidDate(start) || !isValidDate(end)) return false;

            const day_time = 24 * 60 * 60 * 1000;
            const range = new Date(end) - new Date(start);
            if (range < 1000 || range > day_time) return false;

            const req = {
                command: "get_sms_excel",
                params: {
                    ...params,
                    fileKey: new Date().getTime(),
                },
            };

            var res = await this.Call(req, true);

            if (res?.data?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return false;

            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = "문자발신내역";
            a.click();
            a.remove();

            return true;
        } catch (e) {
            return false;
        }
    },
};

export default SmsApiService;
