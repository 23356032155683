<template>
    <!-- @blur="this.blur_event" -->
    <section id="select_theme" :tabindex="-1">
        <div @click="func_OnSelectedOpen()" :class="[{ set_scroll: isScroll }]" class="gray select_border">
            <div class="select_text">
                {{
                    this.selectData
                        ? this.selectData.description
                            ? this.selectData.description
                            : this.isTitleText
                        : this.isTitleText
                }}
            </div>
            <div class="select_arrow">
                <img src="@/assets/images/select_gray.png" />
            </div>

            <ul
                class="option_list option_list_view"
                ref="option_list"
                v-show="isSelected"
                :class="[[isScroll ? 'scroll_option_list' : ''], [pIsUpShow ? 'option_list_up' : 'option_list_down']]"
                :style="{ 'max-height': ui_data.max_height + ui_data.offset + 'px' }"
            >
                <li
                    v-for="item in pListItemArray"
                    :key="item"
                    :class="[
                        [item.isGroup ? 'optgroup_gray' : 'option'],
                        [is_disable_value(item) ? 'selected_value' : ''],
                    ]"
                    @click="func_OnItemSelected(item)"
                >
                    {{ item.description }}
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import { range } from "@/js/util/math";
import jquery from "jquery";
/*
    :pIsScroll="true"                                           스크롤 여부
    :pListTitle="'조건'"                                        기본 타이틀 여부(빈값이면 타이틀 x, 리스트 첫번째 값) 및 타이틀 명
    :pDisableValue="'1'"                                        선택 불가 value
    :pDivision="String(index)"                                  셀렉트박스 여러개 구분
    :pIsUpShow = true                                           셀렉트박스 위로 오픈
    :pVisibleCount = 6                                          셀렉트박스 최대 표출 갯수
    :pListItemArray="[                                          셀렉트박스 아이템들.
        :isGroup                                                  셀렉트박스 내 아이템중 서브 타이틀 아이템
    ]
*/
export default {
    name: "CommonSelectList",
    props: {
        pSelectedData: Object,
        pListItemArray: Array,
        pDivision: String,
        pListTitle: {
            type: String,
            default: "목록",
        },
        pDisableValue: {
            type: Array,
            default: null,
        },
        pIsScroll: {
            type: Boolean,
            default: false,
        },
        pIsUpShow: {
            type: Boolean,
            default: false,
        },
        pVisibleCount: {
            type: Number,
            default: 6,
        },
    },
    data() {
        return {
            isSelected: false,
            isTitleText: this.pListTitle,
            selectData: null,
            isScroll: this.pIsScroll,
            nVisibleCount: 0,
            ui_data: {
                item_height: 26,
                group_heigth: 31,
                offset: 8,
                max_height: 0,
            },
        };
    },
    methods: {
        is_disable_value(item) {
            if (this.pDisableValue && this.pDisableValue.length > 0) {
                return this.pDisableValue.some((disable_value) => disable_value === item.value);
            }
            return false;
        },
        func_OnSelectedOpen() {
            const _this = this;
            if (Array.isArray(this.pListItemArray) && _this.pListItemArray.length !== 0) {
                _this.isSelected = !this.isSelected;
                _this.$nextTick(function () {
                    _this.scroll_selected_item();
                });
            } else {
                _this.$ModalManager.showdialog({
                    title: "목록이 없습니다.",
                    buttons: {
                        yes: "",
                    },
                });
            }
        },
        func_OnItemSelected(data) {
            if (data && (data.value || data.value === 0 || data.value === "")) {
                let disable_value_check = true;
                if (this.pDisableValue) {
                    disable_value_check = this.pDisableValue.every((item) => data.value !== item);
                }
                if (disable_value_check) {
                    this.selectData = data;
                    this.$emit("update:pSelectedData", this.selectData);
                }
            }
        },
        blur_event() {
            this.isSelected = false;
        },
        init_event(p) {
            if (p) this.selectData = p;
            else this.selectData = {};
        },
        update_ListItemArray() {
            if (Array.isArray(this.pListItemArray) && this.pListItemArray.length !== 0) {
                // 그룹 묶음이 있을 경우 스크롤 없이 모든 리스트가 보임.
                let max_display_item_length = this.pListItemArray.length;
                if (this.pListItemArray.length > this.nVisibleCount) {
                    max_display_item_length = this.nVisibleCount;
                    this.isScroll = true;
                }
                this.ui_data.max_height = this.get_item_height(this.pListItemArray[max_display_item_length - 1]);
            }
        },
        get_item_height(target_item) {
            let height = 0,
                b_check = true;
            this.pListItemArray.forEach((item) => {
                if (b_check) {
                    height += item?.isGroup ? this.ui_data.group_heigth : this.ui_data.item_height;
                }
                if (target_item && target_item.value == item?.value && target_item.description == item?.description) {
                    b_check = false;
                }
            });
            return height;
        },
        scroll_selected_item() {
            if (this.isSelected && this.isScroll) {
                // 해당 시점 DOM 객체 업데이트가 되지 않아 display none 상태여서 스크롤 되지 않음.
                // 다음 tick 시점 업데이트 이후 작업.
                var parent = this.$refs.option_list;
                if (parent && this.selectData?.value) {
                    const scroll_height = this.get_item_height(this.selectData) - this.ui_data.max_height;
                    jquery(parent).scrollTop(scroll_height > 0 ? scroll_height : 0);
                }
            }
        },
    },
    watch: {
        pSelectedData(newv, oldv) {
            this.selectData = this.pSelectedData;
        },
        pListItemArray: {
            handler: function () {
                this.update_ListItemArray();
            },
            deep: true,
        },
    },
    created() {
        // 그룹 묶음이 있을 경우 스크롤 없이 모든 리스트가 보임.
        this.selectData = this.pSelectedData;
        this.nVisibleCount = this.pVisibleCount;
        this.update_ListItemArray();
    },
};
</script>

<style scoped>
#select_theme {
    height: 26px;
    position: relative;
    text-align: center;
}

#select_theme * {
    text-align: center;
    user-select: none;
}

#select_theme .gray {
    border-radius: 50px;
    position: relative;
    height: 24px;
    width: 100%;
    border: 1px solid #9c9c9c;
    font-size: 16px;
    font-weight: normal;
    color: #6c6c6c;
    cursor: pointer;
}

#select_theme .option_list_view {
    visibility: visible;
    z-index: 50;
    list-style: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
}
#select_theme .option_list_hide {
    display: none;
}

#select_theme .option_list_up {
    bottom: 24px;
}
#select_theme .option_list_down {
    top: 24px;
}
.option_list {
    background-color: #fff;
    border: 1px solid #9c9c9c;
}
.option_list li {
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}
.option {
    padding: 8px 15px 0px 15px;
}
.option:last-of-type {
    padding-bottom: 8px;
}
#select_theme .optgroup_gray {
    font-weight: bold;
    color: #1e3560;
    height: 19px;
    padding: 12px 15px 0px 15px;
}
#select_theme .optgroup_gray:last-of-type {
    margin-bottom: 12px;
}
/* ----------------------------------------- */

.select_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.select_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}
.selected_value {
    pointer-events: none;
    color: #d9d9d9;
}

/* scroll */
#select_theme .set_scroll .option_list {
    overflow: hidden;
    overflow-y: scroll;
}
#select_theme .set_scroll .option_list::-webkit-scrollbar {
    width: 8px;
}
#select_theme .set_scroll .option_list::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
#select_theme .set_scroll .option_list::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
</style>
