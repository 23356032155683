<template>
    <section class="pop-main">
        <div class="content">
            <div class="title">{{ checkStr }} 등록을 하시겠습니까?</div>
            <div class="msg">{{ msg }}</div>
            <div v-if="this.checkin" class="meal">
                <label>
                    <input type="checkbox" v-model="meal" />
                    <span>식사를 하셨으면 체크해주세요.</span>
                </label>
            </div>
            <div class="btns">
                <button class="no" @click="onClick(false)">취소</button>
                <button class="ok" @click="onClick(true)">{{ checkStr }}</button>
            </div>
        </div>
    </section>
</template>

<script>
import { date_format } from "@/js/util/math";

export default {
    props: {
        checkin: {
            type: Boolean,
            default: false,
        },
        time: {
            type: Date,
            default: () => new Date(),
        },
    },
    data() {
        return {
            meal: false,
        };
    },
    computed: {
        checkStr() {
            return this.checkin ? "퇴근" : "출근";
        },
        msg() {
            return `${date_format(this.time, "hh시 mm분")}으로 ${this.checkStr}기록이 작성됩니다.`;
        },
    },
    methods: {
        onClick(type) {
            const param = { result: type ? "yes" : "no" };
            const detail = { meal: this.meal };
            if (this.checkin) {
                param.detail = JSON.stringify(detail);
            }
            this.$ModalManager.close(this, param);
        },
    },
};
</script>

<style lang="scss" scoped>
.pop-main {
    min-width: 500px;
    padding: 40px;
    background-color: #fff;
    border-radius: 15px;
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        color: #1e3560;
    }
    .meal {
        > label {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }

    .title {
        font-weight: bold;
        font-size: 18px;
    }
    .msg {
        font-weight: normal;
        font-size: 16px;
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        button {
            height: 40px;
            width: 170px;
            border-radius: 50px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            border: none;
            &.no {
                background-color: #9c9c9c;
            }
            &.ok {
                background-color: #1e3560;
            }
        }
    }
}
</style>
